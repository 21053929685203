var InputElementManipulator = (function () {

  function init() {
    $("input.form-control-number").each(function($elem) {
      $(this).bind("change keyup input", function() {
        var value = $(this).val().replace(/,/g, '');
        $(this).val(MAXWELL.utils.numberWithCommas(value));
      }.bind(this));
    });

    // Input format for phone numbers
    var phones = [{ "mask": "(###) ###-####"}];
    $('input.form-control-phone-number').inputmask({
      mask: phones,
      greedy: false,
      definitions: { '#': { validator: "[0-9]", cardinality: 1} }
    });

    // Format currency inputs
    $("input.form-control-currency").each(function() {
      var elem = $(this)
      elem.hide();
      var newElement = $(`<input type='text' value=${$(this).val()} class=${elem.attr('class')} />`)
      newElement.removeClass('form-control-currency')
      $(this).after(newElement)
      newElement.inputmask("currency", {
        digits: 0,
        integerDigits: 9,
        autoUnmask: true,
        rightAlign: false
      });
      newElement.keyup(() => {elem.val(newElement.val())})
    });

    $(document).on('change', '[type=email]', function(event) {
      var target = $(event.target)
      target.val(target.val().toString().toLowerCase())
    })

    // Enable all tooltips
    $('[data-toggle="tooltip"]').tooltip({ sanitize: false });
  }
  return {'init': init}
}());

export default InputElementManipulator;
