/* eslint no-console:0 */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import React from 'react'
import ReactDOM from 'react-dom'
import promiseMiddleware from 'redux-promise'
import thunkMiddleware from 'redux-thunk'
import { Provider } from 'react-redux'
import { compose, createStore, applyMiddleware } from 'redux'

import rootReducer from 'reducers/index'

import _ from 'underscore'
import MonkeyPatcher from 'Services/MonkeyPatcher'
import InputElementManipulator from 'end'
import { createLoadable } from 'components/loadable'
import { initializeSentry } from 'utils/sentry'
import toastr from 'utils/toastr'

// password fields
const PasswordStrengthChecker = createLoadable(() => import('components/utils/PasswordStrengthChecker'))
const PasswordFormContents = createLoadable(() => import('components/utils/PasswordForms/PasswordFormContents'))
const BorrowerRegistrationContents = createLoadable(
  () => import('components/utils/PasswordForms/BorrowerRegistrationContents')
)
const StrongPasswordLink = createLoadable(() => import('components/utils/PasswordForms/StrongPasswordLink'))
const BrandLogoContainer = createLoadable(() => import('v2/containers/BrandLogoContainer'))
const PoweredByMaxwell = createLoadable(() => import('components/utils/PoweredByMaxwell'))
const PageInfoStub = createLoadable(() => import('components/PageInfoStub'))

InputElementManipulator.init()
MonkeyPatcher.objectAssign()
MonkeyPatcher.arrayIncludes()

const middlewares = [thunkMiddleware, promiseMiddleware]

initializeSentry('application')

const mainApp = () => {
  // register globally available components
  MAXWELL.react.registerComponent('BrandLogoContainer', BrandLogoContainer)
  MAXWELL.react.registerComponent('PoweredByMaxwell', PoweredByMaxwell)
  MAXWELL.react.registerComponent('PageInfoStub', PageInfoStub)
  MAXWELL.react.registerComponent('PasswordStrengthChecker', PasswordStrengthChecker)
  MAXWELL.react.registerComponent('PasswordFormContents', PasswordFormContents)
  MAXWELL.react.registerComponent('StrongPasswordLink', StrongPasswordLink)
  MAXWELL.react.registerComponent('BorrowerRegistrationContents', BorrowerRegistrationContents)

  // load react components from DOM
  findReactNodes().each((i, elem) => {
    loadReactComponent(elem)
  })

  $(() => {
    $(document).on('DOMNodeInserted', (e) => {
      $(e.target).find("[data-integration-name='react-component']").each((i, elem) => {
        loadReactComponent(elem)
      })
    })
  })

  // Add default redux components
  // const notificationsElement = $("<div data-integration-name='react-redux-component'></div>");
  // notificationsElement.data('options', { name: 'Notifications' })
  // $(document.body).prepend(notificationsElement);
  //
  // const overlaySpinner = $("<div data-integration-name='react-redux-component'></div>");
  // overlaySpinner.data('options', { name: 'OverlaySpinnerContainer' })
  // $(document.body).prepend(overlaySpinner);

  // load react-redux components from DOM
  const reduxNodes = findReduxNodes()
  const partialStates = _.map(reduxNodes, (elem) => { return $(elem).data('options').initialState })
  const initialState = _.extend.apply(this, [{}, ...partialStates])

  // Create one single shared Redux store
  const composeEnhancers = process.env.NODE_ENV !== 'production'
    && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose

  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
    // other store enhancers if any
  )

  window.store = createStore(
    rootReducer,
    initialState,
    enhancer,
  )

  reduxNodes.each((i, elem) => {
    loadReduxComponent(elem)
  })

  $(() => {
    $(document).on('DOMNodeInserted', (e) => {
      $(e.target).find("[data-integration-name='react-redux-component']").each((i, elem) => {
        loadReduxComponent(elem)
      })
    })
  })

  $(document).trigger('maxwell-react:initialized') // subscribe to this event to identify when app is initialized.
}

function loadReactComponent(elem) {
  const $elem = $(elem)
  const componentName = $elem.data('options').name
  const payload = $elem.data('payload')
  const containerElement = React.createElement(MAXWELL.react.components[componentName], payload)

  ReactDOM.render(
    containerElement,
    elem
  )
}

function loadReduxComponent(elem) {
  const $elem = $(elem)
  const componentName = $elem.data('options').name
  const payload = $elem.data('payload')
  const containerElement = React.createElement(MAXWELL.react.components[componentName], payload)

  ReactDOM.render(
    React.createElement(Provider, { store: window.store }, containerElement),
    elem
  )
}

function findReactNodes() {
  if ($) {
    return $("[data-integration-name='react-component']")
  } else {
    console.error('jQuery is not loaded')
  }
}

function findReduxNodes() {
  if ($) {
    return $("[data-integration-name='react-redux-component']")
  } else {
    console.error('jQuery is not loaded')
  }
}

$(() => mainApp())
